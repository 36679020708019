import React, { useContext } from "react";
import Navbar from "../navbar/navbar.js";
import { ThemeContext } from "../../context/ThemeContext";
import Footer from "../footer/footer.js";
import Particles from "react-tsparticles";

import "./style.scss";

const Layout = ({ children }) => {
  const theme = useContext(ThemeContext);

  function closeMenu() {
    theme.setMenuOpen(false);
  }

  return (
    <>
      <Navbar />
      <div className="layout" role="button" tabIndex="0" onClick={closeMenu} onKeyPress={closeMenu}>
        <Particles
          style={{ position: "fixed", zIndex: -1 }}
          params={{
            particles: {
              color: {
                value: "#36aeaf",
              },
              number: {
                value: 5,
              },
              size: {
                value: theme.isMobile ? 50 : 150,
                random: true,
                anim: {
                  enable: false,
                  speed: 80,
                  size_min: 0.7,
                  sync: false,
                },
              },
              shape: {
                type: "circle",
              },
            },
          }}
        />
        {children}
      </div>
      <Footer />

    </>
  );
};

export default Layout;
